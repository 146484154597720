const tau = 2 * Math.PI;  // 360 degrees
const halfpi = Math.PI/2;
const third_tau = tau / 3;

class Gauge {
    constructor(location, config) {
        let defaults = {
            size: 1000,
            value: 0,
            range: [0, 100],
            color: 'lightseagreen',
            title: '',
            shadow_color: '#666666',
            shadow_opacity: 0.8,

            // the following will be calculated below if they don't have incoming values.
            width: null,
            fontSize: null
        };
        Object.keys(config).forEach(k => defaults[k] = config[k]);
        if (defaults.width === null) defaults.width = Math.max(2, defaults.size/25);
        if (defaults.fontSize === null) defaults.fontSize = defaults.size * 0.45;

        this._curval = defaults.range[0];
        this.location = location;
        this.config = defaults;
        this.title = this.config.title;
        this._baseFontSize = this.config.fontSize;
        this.scale = d3.scaleLinear()
                       .domain(this.config.range)
                       .range([-third_tau, third_tau]);
        this.construct();
    }

    get min()   { return this.config.range[0]; }
    get max()   { return this.config.range[1]; }
    get size()  { return this.config.size; }
    get value() { return this.config.value; }
    set value(v) { this.config.value = v; this.draw(v); }
    get width() { return this.config.width; }
    get color() { return this.config.color; }
    get textColor() { return this.config.textColor || '#eeeeee'; }
    get range() { return this.max - this.min; }
    get outerRadius() { return this.size/2; }
    get innerRadius() { return this.outerRadius - this.width; }
    get fontSize() { return (''+this.value).length <= 2 ? this._baseFontSize : this._baseFontSize * 0.8; }
    get inset() { return Math.max(1, this.width/10); }

    construct() {
        /*
         *  Construct the shell of the widget
         */

        // arc with start angle
        this.arc = d3.arc()
            .startAngle(this.scale(0));

        this.bbox = d3.select(this.location)
            .append('svg')
            .attr('width', this.size)
            .attr('height', this.size);

        this.defs = this.bbox.append('defs');
        this.defs.html(`
            <filter id="gauge-dropshadow">
                <feOffset dx="2" dy="2"></feOffset>
                <feFlood flood-color="#666666" flood-opacity="0.8" result="f1"></feFlood>
                <feComposite operator="in" in="f1" in2="SourceAlpha"></feComposite>
                <feGaussianBlur stdDeviation="1" result="blurOut"></feGaussianBlur>
                <feBlend in="SourceGraphic" in2="blurOut" mode="normal"></feBlend>
            </filter>
        `);

        // a group with correct size and 0,0 in the 'middle'
        this.svg = this.bbox
            .append('g')
                // the scale is needed to keep the drop-shadow from being cut off
                .attr('transform', `translate(${this.size/2}, ${this.size/2}) scale(0.85)`);

        // grey background arc that the value-arc is drawn ontop of.
        this.rail = this.svg
            .append("path")
                .attr('class', 'gauge-rail')
                .style('fill', '#efefef')
                .attr('d', d3.arc()  //this.arc
                    .outerRadius(this.outerRadius - this.inset)
                    .innerRadius(this.innerRadius + this.inset)
                    .startAngle(this.scale(this.min))
                    .endAngle(this.scale(this.max)));

        // the gauge title (placed at the bottom, under the value
        this.titlebox = this.svg
            .append('text')
                .attr('class', 'gauge-title')
                .attr('x', 0)
                .attr('y', this._baseFontSize)
                .attr('dy', '-0.5em')
                .attr('text-anchor', 'middle')
                .attr('font-size', this._baseFontSize/4)
                .style('color', this.textColor)
                .attr('font-weight', 400);

        this.value_arc = d3.arc()
                           .startAngle(this.scale(0))
                           .outerRadius(this.outerRadius)
                           .innerRadius(this.innerRadius);

        // the arc representing the value
        this.value_arc_path = this.svg
            .append("path")
                .datum({endAngle: this.scale(this.min)})
                .attr('class', 'gauge-arc')
                .style('fill', this.color)
                // .style('filter', 'url(#gauge-dropshadow)')
                .attr('d', this.value_arc);

        // text version of value
        this.textbox = this.svg
            .append('text')
            .attr('class', 'gauge-text')
            .attr('x', 0)
            .attr('y', 0)
            .attr('dy', '0.35em')
            // .attr('dy', this._baseFontSize/2)
            .attr('text-anchor', 'middle')
            .attr('font-weight', 100);

        this.draw(this.config.value);
    }

    draw(val) {
        // draw the value in the widget
        val = val || this._curval;
        let curval = this._curval;
        this.draw_text(val, curval);

        this.draw_arc(
            this.scale(val),
            this.config.color,
            this.scale(curval)
        );
        this._curval = val;
        this.draw_title();
    }

    draw_title() {
        this.titlebox.text(this.title);
    }

    draw_text(val, curval) {
        this.svg.select('.gauge-text')
            .attr('font-size', this.fontSize)
            .transition().duration(750)
            .on('start', function () {
                d3.active(this)
                    .tween('text', function () {
                        let self = d3.select(this);
                        let interpfn = d3.interpolateNumber(curval, val);
                        return t => self.text(interpfn(t)|0);
                    })
            });
    }

    draw_arc(end, color, oldval) {
        let self = this;
        this.value_arc_path
            .transition().duration(750)
            .attrTween('d', function (d) {
                let interpfn = d3. interpolate(d.endAngle, end);
                return function (t) {
                    d.endAngle = interpfn(t);
                    return self.value_arc(d);
                }
            });
    }
}
